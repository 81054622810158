html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: proxima-nova, "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
  color: "#4A4A4A";
}

#root {
  background-color: #f7f7f7;
}

.ql-snow strong {
  font-weight: bold !important;
}

p {
  margin: 0;
}

.quill-editor-image-embed {
  max-width: 100%;
}

.rt-table {
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 20, 0.2), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 20, 0.2), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

button:focus {
  outline: none;
}
